/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function stripHTML(content) {
    if (!content) {
      return ""
    }
    // Remove HTML
    let newDiv = document.createElement("div");
    newDiv.innerHTML = content;
    let detailsAsText = newDiv.innerText;
    // Add spaces at the begining of sentences. 
    const addSpaceRegex = /[\.]([a-z,A-Z])/i;
    return detailsAsText.replace(addSpaceRegex, (m, ...a) => {return a ? `. ${a[0]}` : ""});
}