// Modules exported here will be included in bundle.js
export const initResultsCountObserver = () => {
  const filterCountWrapper = document.querySelector('#js-answersVerticalResultsCount');
  if (filterCountWrapper) {
    const config = { attributes: true, childList: true, subtree: true };
    const callback = function(mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.addedNodes) {
          for (const node of mutation.addedNodes) {
            if (node.classList?.contains('yxt-VerticalResultsCount')) {
              const resultsCountSpan = document.querySelector('.yxt-VerticalResultsCount-total');
              let resultsLimit = 200;

              if (resultsCountSpan) {
                const resultsCount = Number(resultsCountSpan.innerHTML);

                if (resultsCount > resultsLimit) {
                  const pageNumbers = document.querySelectorAll('.js-yxt-Pagination-link');
                  for (const pageNumber of pageNumbers) {
                    if (Number(pageNumber.innerHTML) > resultsLimit/20) {
                      pageNumber.style.display = 'none';
                    }
                  }

                  resultsCountSpan.innerHTML = resultsLimit.toString();
                  const resultsEndSpan = document.querySelector('.yxt-VerticalResultsCount-end');
                  if (resultsEndSpan) {
                    const resultsEnd = Number(resultsEndSpan.innerHTML);
                    if (resultsEnd == resultsLimit) {
                      const resultsNext = document.querySelector('.js-yxt-Pagination-next');
                      if (resultsNext) {
                          resultsNext.style.display = 'none';
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    const observer = new MutationObserver(callback);
    observer.observe(filterCountWrapper, config);
  };
}

export const locationBiasListener = () => {
  const locationBiasButton = document.querySelector('.js-locationBias-update-location');
  if (locationBiasButton) {
    locationBiasButton.addEventListener('keydown', function(e) {
      if (e.keyCode == '32') {
        e.preventDefault();
      }
    })
  }
}
export function showCopiedMessage(button, eventType, card) {
  card["eventType"] = eventType;
  card["isDirectAnswer"] = false;
  parent.postMessage(card, "*");

  var optionText = button.getElementsByClassName("copyMenu-copy-option")[0];
  optionText.classList.add('not-visible');
  optionText.classList.remove('made-visible');
  var copiedText = button.getElementsByClassName("copyMenu-copied-message")[0];
  copiedText.classList.add('made-visible');
  setTimeout(function() { 
    optionText.classList.add('made-visible');
    optionText.classList.remove('not-visible');
    copiedText.classList.remove('made-visible');
    }, 2000);
};

export function showCopiedMessageDirect(button, eventType, title, value) {
  var card = {"eventType": eventType, "isDirectAnswer": true, "title": title, "value": value};
  parent.postMessage(card, "*");

  var optionText = button.getElementsByClassName("copyMenu-copy-option")[0];
  optionText.classList.add('not-visible');
  optionText.classList.remove('made-visible');
  var copiedText = button.getElementsByClassName("copyMenu-copied-message")[0];
  copiedText.classList.add('made-visible');
  setTimeout(function() { 
    optionText.classList.add('made-visible');
    optionText.classList.remove('not-visible');
    copiedText.classList.remove('made-visible');
    }, 2000);
};
